import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { RiHomeLine } from "react-icons/ri"

// Import your image from the images folder
import overlayImage from "../images/me.png"

// Global styles
const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Raleway', sans-serif;
    background-color: #f3efee; /* Fallback for older browsers */
    overflow: hidden; /* Prevent scrolling */
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; // Changed to min-height for full height
  }
`

// Container for the entire content
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
  min-height: 100vh;
`

// MainContent for the main section
const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  padding-top: 4rem; /* To ensure the content does not overlap with the fixed header */
`

// Header styled component
const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f3efee; /* Match background color */
  position: fixed;
  top: 0;
  z-index: 1000; /* Ensure it stays on top of other elements */
  left: 0;
  right: 0;
`

const NavLinks = styled.div`
  display: flex;
  align-items: center;
`

const NavLink = styled.a`
  color: #333;
  text-decoration: none;
  margin-left: 1rem;
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #555;
  }

  &:first-child {
    margin-left: 0; /* Remove left margin for the first child */
  }
`

// Left section for the image
const LeftSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

// Image styled component
const Image = styled.img`
  max-width: 100%;
  height: auto;
`

// Right section for about me text
const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Padding inside the section */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Add box shadow */
  margin-right: 2rem;
`

// About Me text styled component
const AboutMeText = styled.p`
  font-family: 'Raleway', sans-serif;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
`

// Footer styled components
const Footer = styled.footer`
  width: 100%;
  background-color: #f3efee; /* Match background color with the rest of the page */
  color: #333;
  text-align: center;
  padding: 10px 0;
  margin-top: auto; /* Push the footer to the bottom */
`

const HeartIcon = styled.span`
  color: #ff4f78; /* Heart icon color */
`

// Made with Love styled component
const MadeWithLove = styled.p`
  margin: 5px 0 0;
  font-size: 0.8rem;
`

// Main component
const AboutPage = () => (
  <Container>
    <GlobalStyle />
    <Header>
      <NavLink href="/">
        <RiHomeLine />
      </NavLink>
    </Header>
    <MainContent>
      <LeftSection>
        <Image src={overlayImage} alt="Me" />
      </LeftSection>
      <RightSection>
        <AboutMeText>
          I'm a passionate developer, always eager to learn and explore new technologies.
          Follow me on my social media channels to stay updated with my latest projects and ideas.
        </AboutMeText>
      </RightSection>
    </MainContent>
    <Footer>
      <MadeWithLove>
        Made with <HeartIcon>&hearts;</HeartIcon> in Bangalore, 2024
      </MadeWithLove>
    </Footer>
  </Container>
)

export default AboutPage
